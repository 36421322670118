import React from "react"
import { Link } from "gatsby"
import style from "./AgendaPage.module.scss"
import createPersistedState from "use-persisted-state"

const AgendaSection = ({ section, hero }) => {
  const useCounterState = createPersistedState(`${section.id}_bookmark`)
  const [Checked, setChecked] = useCounterState(false)

  let content = (
    <>
      <div
        className={style.title}
        dangerouslySetInnerHTML={{
          __html:
            section.name && section.name.replace(/(?:\r\n|\r|\n)/g, "<br/>"),
        }}
      ></div>

      <div className={style.meta}>
        {section.speakers.length > 0 && (
          <div className={style.speakerWrap}>
            {section.speakers[0].avatar && (
              <div className={style.avatar}>
                <img
                  src={section.speakers[0].avatar.childImageSharp.resize.src}
                  alt={section.speakers[0].name.name}
                />
              </div>
            )}
            <div className={style.speakerName}>
              {section.speakers[0].name.name}
              <br />
              {section.speakers[0].job.corp}
              <br />
              {section.speakers[0].job.job_title}
            </div>
          </div>
        )}
        {section.location && (
          <div className={style.location}>
            <svg width="14px" height="16px" viewBox="0 0 14 18">
              <g
                id="Page-1"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g
                  id="議程_mobile"
                  transform="translate(-239.000000, -1791.000000)"
                  fillRule="nonzero"
                >
                  <g
                    id="foundation-marker"
                    transform="translate(235.000000, 1789.000000)"
                  >
                    <g
                      id="Icon"
                      transform="translate(4.000000, 2.000000)"
                      fill="#FFFFFF"
                    >
                      <path
                        d="M7,0.29174 C3.57218,0.29174 0.795771836,3.06968 0.795771836,6.49596 C0.793604936,7.83415389 1.2261144,9.13685511 2.02822,10.20802 L6.0078,17.10106 C6.0309,17.1455 6.054,17.18972 6.08238,17.23042 L6.09118,17.2456 L6.0936,17.24428 C6.30380344,17.5371869 6.64277596,17.7102392 7.0033,17.7087102 C7.34562,17.7087102 7.64394,17.55294 7.8492,17.31292 L7.85954,17.31886 L7.89892,17.25066 C7.957,17.17234 8.00848,17.08874 8.04566,16.99656 L11.9445,10.243 C12.7635767,9.16572652 13.2061629,7.84925263 13.2042264,6.49596 C13.2042264,3.06968 10.42782,0.29174 7,0.29174 L7,0.29174 Z M6.93862,9.6413 C5.24968,9.6413 3.88172,8.27268 3.88172,6.5844 C3.88172,4.89634 5.24968,3.5275 6.93862,3.5275 C8.62756,3.5275 9.99552,4.89634 9.99552,6.5844 C9.99552,8.27246 8.62756,9.6413 6.93862,9.6413 L6.93862,9.6413 Z"
                        id="Icon-Shape"
                      ></path>
                    </g>
                    <rect
                      id="ViewBox"
                      x="0"
                      y="0"
                      width="22"
                      height="22"
                    ></rect>
                  </g>
                </g>
              </g>
            </svg>
            {section.location}
          </div>
        )}
      </div>
    </>
  )

  if (section.session_type.find(v => v.id === 2)) {
    content = (
      <Link
        className={[
          style.section,
          hero ? style.hero : "",
          section.speakers.length > 0 ? style.speaker : "",
        ].join(" ")}
        state={{
          modal: true,
        }}
        onClick={() => {
          localStorage.setItem(`${section.id}_bookmark`, true)
          setChecked(true)
        }}
        to={`/agenda/section/${section.strapiId}`}
      >
        {content}
        {section.stream && (
          <div className={style.online}>
            <input
              id={section.id}
              type="checkbox"
              onChange={e => {
                setChecked(e.target.checked)
                localStorage.setItem(
                  `${e.target.id}_bookmark`,
                  e.target.checked
                )
              }}
              checked={Checked}
            ></input>
            <label
              onClick={e => {
                console.log("test")
              }}
            ></label>
          </div>
        )}
      </Link>
    )
  } else {
    content = (
      <div
        className={[
          style.section,
          hero ? style.hero : "",
          section.speakers.length > 0 ? style.speaker : "",
        ].join(" ")}
      >
        {content}
      </div>
    )
  }

  return (
    <div
      className={[
        style.sectionWrap,
        section.session_type.find(v => v.id === 2) ? style.link : " ",
      ].join(" ")}
    >
      {content}
    </div>
  )
}

export default AgendaSection
