import React, { useState } from "react"
import { Link } from "gatsby"
import style from "./AgendaPage.module.scss"
import AgendaSection from "./AgendaSection"
import QASection from "./QASection"

const padNum = function (n) {
  const s = n.toString()
  return s.padStart("2", "0")
}

const AgendaPage = ({ session, qa }) => {
  const [checkTag, setTag] = useState([])
  const [checkVideo, setVideo] = useState(false)

  let tags = new Set()
  let times = new Map()
  let ss = session
  let tagsItems = []
  let timesItems = []
  if (qa) {
    session.forEach((v,i) => {

      timesItems.push(
        <div key={i} className={style.time}>
          <QASection hero={true} key={i} section={v} />
        </div>
      )
      
    })

  } else {
    for (const s of ss) {
      if (s.tag.length) {
        for (const t of s.tag) {
          if (t.tag) {
            tags.add(t.tag)
          }
        }
      }
    }

    if (checkTag.length > 0) {
      ss = ss.filter(s => {
        return [...s.tag].filter(st => checkTag.indexOf(st.tag) > -1).length > 0
      })
    }

    if (checkVideo) {
      ss = ss.filter(s => {
        return s.stream
      })
    }
    for (const s of ss) {
      times.set(s.start_time, s.end_time)
    }

    tags.forEach(v => {
      tagsItems.push(
        <div key={v} className={style.tagItem}>
          <input
            type="checkbox"
            id={v}
            value={v}
            onChange={e => {
              if (e.target.checked) {
                checkTag.push(e.target.value)
                setTag([...checkTag])
              } else {
                checkTag.splice(checkTag.indexOf(e.target.value), 1)
                setTag([...checkTag])
              }
            }}
          ></input>
          <label htmlFor={v}>{v}</label>
        </div>
      )
    })

    times.forEach((end_time, start_time) => {
      const timeSection = ss.filter(s => s.start_time === start_time)
      timeSection.sort((a, b) => {
        if (a.location && a.location.indexOf("11") !== -1) {
          return -1
        } else if (b.location && b.location.indexOf("11") !== -1) {
          return 1
        } else return a.location > b.location ? 1 : -1
      })

      timesItems.push(
        <div key={start_time} className={style.time}>
          <span className={style.label}>
            {padNum(new Date(start_time).getHours())}:
            {padNum(new Date(start_time).getMinutes())} -
            {padNum(new Date(end_time).getHours())}:
            {padNum(new Date(end_time).getMinutes())}
          </span>
          {timeSection.map(e => (
            <AgendaSection
              hero={timeSection.length === 1}
              key={e.id}
              section={e}
            />
          ))}
        </div>
      )
    })
  }

  return (
    <div className={style.agenda}>
      <div className={style.dateContainer}>
        <Link to="/agenda/2021-05-14/" activeClassName={style.active}>
          5/14 (五)
        </Link>
        <Link to="/agenda/2021-05-15/" activeClassName={style.active}>
          5/15 (六)
        </Link>
        <Link to="/agenda/2021-05-16/" activeClassName={style.active}>
          5/16 (日)
        </Link>
        <Link to="/agenda/qa/" activeClassName={style.active}>
          線上 QA
        </Link>
      </div>
      <div className={style.tags}>
        {!qa && (
          <>
            {tagsItems}
            <span className={style.split}></span>
            <div className={style.tagItem + " " + style.tagVideo}>
              <input
                type="checkbox"
                id={"video"}
                value={"video"}
                onChange={e => setVideo(e.target.checked)}
              ></input>
              <label htmlFor={"video"}>影片</label>
            </div>
          </>
        )}
      </div>
      <div className={style.times}>{timesItems}</div>
    </div>
  )
}

export default AgendaPage
