import React from "react"
import { Link } from "gatsby"
import style from "./AgendaPage.module.scss"
import createPersistedState from "use-persisted-state"

const AgendaSection = ({ section, hero }) => {
  const useCounterState = createPersistedState(`${section.id}_bookmark`)
  const [Checked, setChecked] = useCounterState(false)
  let content = (
    <>
      <div
        className={style.title}
        dangerouslySetInnerHTML={{
          __html:
            section.name && section.name.replace(/(?:\r\n|\r|\n)/g, "<br/>"),
        }}
      ></div>

      <div className={style.qameta}>
        {section.speakers.map(speaker => (
          <div className={style.speakerWrap}>
            {speaker.avatar && (
              <div className={style.avatar}>
                <img
                  src={speaker.avatar.childImageSharp.resize.src}
                  alt={speaker.name.name}
                />
              </div>
            )}
            <div className={style.speakerName}>
              {speaker.name.name}
              <br />
              {speaker.job.corp}
              <br />
              {speaker.job.job_title}
            </div>
          </div>
        ))}
      </div>
    </>
  )

  if (section.session_type.find(v => v.id === 2)) {
    content = (
      <Link
        className={[
          style.section,
          hero ? style.hero : "",
          section.speakers.length > 0 ? style.speaker : "",
        ].join(" ")}
        state={{
          modal: true,
        }}
        onClick={() => {
          localStorage.setItem(`${section.id}_bookmark`, true)
          setChecked(true)
        }}
        to={`/agenda/qa/${section.strapiId}`}
      >
        {content}
        {section.stream && (
          <div className={style.online}>
            <input
              id={section.id}
              type="checkbox"
              onChange={e => {
                setChecked(e.target.checked)
                localStorage.setItem(
                  `${e.target.id}_bookmark`,
                  e.target.checked
                )
              }}
              checked={Checked}
            ></input>
            <label
              onClick={e => {
                console.log("test")
              }}
            ></label>
          </div>
        )}
      </Link>
    )
  } else {
    content = (
      <div
        className={[
          style.section,
          hero ? style.hero : "",
          section.speakers.length > 0 ? style.speaker : "",
        ].join(" ")}
      >
        {content}
      </div>
    )
  }

  return (
    <div
      className={[
        style.sectionWrap,
        section.session_type.find(v => v.id === 2) ? style.link : " ",
      ].join(" ")}
    >
      {content}
    </div>
  )
}

export default AgendaSection
