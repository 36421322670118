import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import HeroImage from "../../components/HeroImage"
import AgnedaPage from "../../components/AgendaPage"

const Agenda = ({ pageContext }) => {
  const AgendaQuery = useStaticQuery(
    graphql`
      query AgendaQuery {
        allStrapiSession(filter: { event: { id: { eq: 1 } } }) {
          nodes {
            id
            start_time
            stream
            speakers {
              avatar {
                childImageSharp {
                  resize(width: 320) {
                    src
                    width
                    height
                    aspectRatio
                  }
                }
              }
              name {
                name
                origional_name
              }
              job {
                corp
                id
                job_title
              }
            }
            location
            strapiId
            end_time
            session_type {
              id
              name
            }
            name
            tag {
              tag
            }
          }
        }
        pageQuery: allStrapiSession(
          filter: {
            event: { id: { eq: 1 } }
            session_type: { elemMatch: { id: { eq: 2, ne: 4 } } }
          }
        ) {
          nodes {
            sectionPage: gatsbyPath(
              filePath: "/agenda/section/{StrapiSession.strapiId}"
            )
          }
        }
        allStrapiWriting(filter: { key: { eq: "agenda_header" } }) {
          nodes {
            key
            writing
          }
        }
      }
    `
  )
  const writing = AgendaQuery.allStrapiWriting.nodes[0].writing
  const session = AgendaQuery.allStrapiSession.nodes
    .filter(v => {
      return v.start_time.match(`^${pageContext.id}`)
    })
    .sort((a, b) => {
      return new Date(a.start_time).getTime() - new Date(b.start_time).getTime()
    })

  const date = pageContext.id.split("-")

  return (
    <Layout>
      <SEO title={`${date[1]}月${date[2]}日議程`} />
      <HeroImage
        writing={writing || ""}
        portrait="/images/hero.agenda.portrait.svg"
        landscape="/images/hero.agenda.landscape.svg"
      />
      <AgnedaPage session={session} />
    </Layout>
  )
}

export default Agenda
